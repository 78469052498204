<!--
 * @Description: 招聘信息
 * @Author: huacong
 * @Date: 2021-04-19 17:44:18
 * @LastEditTime: 2021-07-23 15:56:01
 * @LastEditors: huacong
-->
<template>
  <!-- 招聘信息 -->
  <div class="contentBox">
    <section style="padding-top: 20px">
      <el-form :model="searchInfo">
        <el-row :gutter="16">
          <el-col :span="5">
            <el-select
              v-model="searchInfo.j_certificate"
              @change="getCerMajor"
              placeholder="选择分类"
              clearable
            >
              <el-option
                v-for="(item, index) in selCerList"
                :key="index"
                :label="item.certificate"
                :value="item.certificate"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="searchInfo.j_major"
              placeholder="选择专业"
              clearable
            >
              <el-option
                v-for="(item, index) in cerChildList"
                :key="index"
                :label="item.certificate"
                :value="item.certificate"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <el-cascader
              size="large"
              :options="comcity"
              :props="{ checkStrictly: true }"
              v-model="selectedOptions"
              @change="setUseInfoSocial"
              placeholder="选择省份或城市"
              style="width: 100%;"
              clearable
            >
            </el-cascader>
          </el-col>
          <el-col :span="5">
            <el-input v-model="searchInfo.id" placeholder="职位编号"></el-input>
          </el-col>
          <el-col :span="2">
            <el-button
              type="primary"
              v-prevent-repeat-click
              @click="searchPost"
              class="radius-25"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </el-form>
      <div style="padding: 30px 0 10px;">
        <el-tabs v-model="tabName" :stretch="true" @tab-click="changetab">
          <el-tab-pane label="显示中的信息" name="first"></el-tab-pane>
          <el-tab-pane label="急招职位" name="second"></el-tab-pane>
          <el-tab-pane label="热招职位" name="third"></el-tab-pane>
          <el-tab-pane label="精选职位" name="fourth"></el-tab-pane>
          <el-tab-pane label="已过期" name="five"></el-tab-pane>
          <el-tab-pane label="已删除" name="six"></el-tab-pane>
          <el-tab-pane label="审核中" name="seven"></el-tab-pane>
          <el-tab-pane label="未通过" name="eight"></el-tab-pane>
        </el-tabs>
      </div>
      <div style="padding-bottom: 20px">
        <el-radio-group v-model="infoType" size="mini" @change="changePostType">
          <el-radio-button label="招聘信息"></el-radio-button>
          <el-radio-button label="求职信息"></el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="workType"
          size="mini"
          style="float: right;"
          @change="changeWorkType"
          v-if="
            (tabName == 'first' ||
              tabName == 'five' ||
              tabName == 'six' ||
              tabName == 'seven' ||
              tabName == 'eight') &&
              workTypeShow
          "
        >
          <el-radio-button label="1">兼职</el-radio-button>
          <el-radio-button label="2">全职</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button size="mini" v-prevent-repeat-click @click="delAll">
          批量删除
        </el-button>
      </div>
      <!-- 发布职位信息 -->
      <div class="tableBox" v-if="workTypeShow">
        <!-- 显示中的信息 -->
        <el-table
          :data="tableInfo1"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="selAllInfo1"
        >
          <el-table-column type="selection" width="45"> </el-table-column>
          <el-table-column label="全选本页信息">
            <!-- width="340" -->
            <template slot-scope="scope">
              <div>
                <p class="fontWeight">
                  <span v-if="searchInfo.type == 1">
                    [兼职]
                    <span class="fontHover" @click="gopartTime(scope.row)">
                      {{ scope.row.j_certificate }}-{{ scope.row.j_major }}
                    </span>
                  </span>
                  <span v-if="searchInfo.type == 2">
                    [全职]
                    <span class="fontHover" @click="gofullTime(scope.row)">
                      {{ scope.row.j_certificate }}-{{ scope.row.j_major }}
                    </span>
                  </span>
                </p>
                <p>
                  <span style="margin-right: 20px;">
                    编号：{{ scope.row.id }}
                  </span>
                  <span v-if="searchInfo.type == 1">
                    {{ scope.row.j_area_province }}
                    -
                    {{ scope.row.j_area_city }}
                  </span>
                  <span v-if="searchInfo.type == 2">
                    {{ scope.row.j_province }}
                    -
                    {{ scope.row.j_city }}
                  </span>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="">
            <!-- width="200" -->
          </el-table-column>
          <el-table-column
            label=""
            v-if="tabName != 'five' && tabName != 'six'"
          >
            <template slot-scope="scope">
              <div class="btnBox1 text-center">
                <el-button
                  type="text"
                  v-prevent-repeat-click
                  @click="editWorkbtn(scope.row)"
                  style="color: #606266"
                >
                  修改
                </el-button>
                <el-button
                  type="text"
                  v-prevent-repeat-click
                  @click="refresh(scope.row.id)"
                  v-show="tabName != 'seven' && tabName != 'eight'"
                >
                  刷新
                </el-button>
                <el-popconfirm
                  title="这条信息确定删除吗？"
                  @confirm="delInfo(scope.row.id)"
                >
                  <el-button
                    type="text"
                    slot="reference"
                    style="color: #F56C6C"
                    v-prevent-repeat-click
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label=""
            v-if="
              tabName != 'five' &&
                tabName != 'six' &&
                tabName != 'seven' &&
                tabName != 'eight'
            "
          >
            <template slot-scope="scope">
              <div>
                <div class="btnBox1 text-center" style="margin-bottom: 5px">
                  <el-button
                    type="text"
                    v-prevent-repeat-click
                    @click="comTag(scope.row.id, 3)"
                  >
                    急招职位
                  </el-button>
                  <el-button
                    type="text"
                    v-prevent-repeat-click
                    @click="comTag(scope.row.id, 4)"
                  >
                    精选职位
                  </el-button>
                </div>
                <div class="btnBox1 text-center">
                  <el-button
                    type="text"
                    v-prevent-repeat-click
                    @click="comTag(scope.row.id, 2)"
                  >
                    热招职位
                  </el-button>
                  <el-button type="text" v-prevent-repeat-click @click="buyVip">
                    购买会员
                  </el-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <div>
              <img :src="noDate" />
            </div>
            <span>暂无数据</span>
          </div>
        </el-table>
        <div class="pageBox">
          <el-pagination
            background
            @size-change="sizeChange1"
            @current-change="currentChange1"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 求职列表 -->
      <div class="tableBox" v-if="postShow">
        <!-- 显示中的信息 -->
        <el-table
          :data="tableInfo2"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="selAllInfo1"
        >
          <el-table-column type="selection" width="45"> </el-table-column>
          <el-table-column label="全选本页信息" width="540">
            <template slot-scope="scope">
              <div>
                <p class="fontWeight">
                  {{ scope.row.certificate }}-{{ scope.row.major }}-{{
                    scope.row.register
                  }}
                </p>
                <p>
                  <span style="margin-right: 20px;">
                    编号：{{ scope.row.id }}
                  </span>
                  <span>
                    {{ scope.row.expected_province }}
                    -
                    {{ scope.row.expected_city }}
                  </span>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="" width="400">
          </el-table-column>
          <!-- <el-table-column prop="zs_price" label="" width="200">
          </el-table-column> -->
          <el-table-column label="">
            <template slot-scope="scope">
              <div class="btnBox1">
                <el-button
                  type="text"
                  v-prevent-repeat-click
                  @click="editPostbtn(scope.row)"
                  style="color: #606266"
                >
                  修改
                </el-button>
                <el-button
                  type="text"
                  v-prevent-repeat-click
                  @click="refresh(scope.row.id)"
                >
                  刷新
                </el-button>
                <el-popconfirm
                  title="这条信息确定删除吗？"
                  @confirm="delInfo(scope.row.id)"
                >
                  <el-button
                    type="text"
                    slot="reference"
                    style="color: #F56C6C"
                    v-prevent-repeat-click
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <div>
              <img :src="noDate" />
            </div>
            <span>暂无数据</span>
          </div>
        </el-table>
        <div class="pageBox">
          <el-pagination
            background
            @size-change="sizeChange2"
            @current-change="currentChange2"
            :current-page="currentPage2"
            :page-sizes="[10, 20, 30]"
            :page-size="pagesize2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal2"
          >
          </el-pagination>
        </div>
      </div>
    </section>
    <!-- 修改发布职位的信息 -->
    <el-dialog
      title="修改发布职位"
      :close-on-click-modal="false"
      width="900px"
      :visible.sync="editworkDialog"
    >
      <jobInfo ref="editInfo"></jobInfo>
      <div class="text-center">
        <el-button
          v-prevent-repeat-click
          type="primary"
          @click="edit1"
          class="radius-25"
        >
          提交
        </el-button>
      </div>
    </el-dialog>
    <!-- 修改求职信息 -->
    <el-dialog
      title="修改职位信息"
      :close-on-click-modal="false"
      width="900px"
      :visible.sync="editPostDialog"
    >
      <applyJob ref="editpost"></applyJob>
      <div class="text-center">
        <el-button
          v-prevent-repeat-click
          type="primary"
          @click="edit2"
          class="radius-25"
        >
          提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import jobInfo from "@/view/comPage/postJob/jobInfo";
import applyJob from "@/view/comPage/postJob/applyJob";
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      searchInfo: {},
      selJobList: [],
      selCerList: [],
      editworkDialog: false,
      editPostDialog: false,
      cerChildList: [],
      selectedOptions: [],
      comcity: provinceAndCityData,
      tabName: "first",
      infoType: "招聘信息",
      workType: "1",
      workTypeShow: true,
      postShow: false,
      tableInfo1: [],
      tableInfo2: [],
      idsList: [],
      dataLength: 0,
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      currentPage2: 1,
      pagesize2: 10,
      tableTotal2: 0,
      editInfoTar: {},
    };
  },
  components: {
    jobInfo,
    applyJob,
  },
  computed: {
    userInfo() {
      let k = localStorage.getItem("userInfo");
      console.log(k);
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    // 职位下拉
    getJobList() {
      this.$http.post("index/certificate/postion").then((res) => {
        this.selJobList = res.data.resultData;
      });
    },
    // 证书下拉
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
    },
    // 获取证书专业
    getCerMajor(e) {
      this.selCerList.forEach((element) => {
        if (element.certificate == e) {
          this.cerChildList = element.child;
          this.$set(this.searchInfo, "j_major", "");
        }
      });
    },
    // 设置省市
    setUseInfoSocial() {
      this.searchInfo.province = CodeToText[this.selectedOptions[0]];
      this.searchInfo.city = CodeToText[this.selectedOptions[1]];
    },
    changeWorkType() {
      this.getTableData1();
    },
    // 获取职位显示中信息  table
    getTableData1(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.searchInfo.type = this.workType;
      this.$http.post("company/job/joblist", this.searchInfo).then((res) => {
        this.tableInfo1 = res.data.resultData.list;
        this.dataLength = res.data.resultData.count;
        this.tableTotal = parseInt(res.data.resultData.count);
      });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getTableData1(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getTableData1(e, this.pagesize);
    },
    // 获取求职显示信息 table
    getTableData2(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage1;
        this.searchInfo.limit = this.pagesize2;
      }
      this.$http
        .post("company/resume/resumelist", this.searchInfo)
        .then((res) => {
          this.tableInfo2 = res.data.resultData.list;
          this.dataLength = res.data.resultData.count;
          this.tableTotal2 = parseInt(res.data.resultData.count);
        });
    },
    sizeChange2(e) {
      this.pagesize2 = e;
      this.currentPage2 = 1;
      this.getTableData2(this.currentPage2, this.pagesize2);
    },
    currentChange2(e) {
      this.currentPage2 = e;
      this.getTableData2(e, this.pagesize2);
    },
    changePostType(e) {
      if (e == "招聘信息") {
        this.postShow = false;
        this.workTypeShow = true;
      } else {
        this.workTypeShow = false;
        this.getTableData2();
        this.postShow = true;
      }
    },
    // 列表select
    selAllInfo1(e) {
      console.log(e);
      this.idsList = [];
      e.forEach((element) => {
        this.idsList.push(element.id);
      });
    },
    // 切换tab
    changetab(e) {
      console.log(e.name);
      switch (e.name) {
        case "first":
          this.searchInfo.position_type = "";
          this.searchInfo.approval_status = "2";
          break;

        case "second":
          this.searchInfo.position_type = "3";
          this.searchInfo.approval_status = "2";
          break;

        case "third":
          this.searchInfo.position_type = "2";
          this.searchInfo.approval_status = "2";
          break;

        case "fourth":
          this.searchInfo.position_type = "4";
          this.searchInfo.approval_status = "2";
          break;

        case "five":
          this.searchInfo.position_type = "";
          this.searchInfo.approval_status = "4";
          break;

        case "six":
          this.searchInfo.position_type = "";
          this.searchInfo.approval_status = "5";
          break;

        case "seven":
          this.searchInfo.position_type = "";
          this.searchInfo.approval_status = "1";
          break;

        case "eight":
          this.searchInfo.position_type = "";
          this.searchInfo.approval_status = "3";
          break;
      }
      this.searchPost();
    },
    // 搜索按钮
    searchPost() {
      if (this.infoType == "招聘信息") {
        this.getTableData1(); // 招聘信息
      } else {
        this.getTableData2(); // 求职信息
      }
    },
    // 刷新
    refresh(e) {
      // this.$http.post("company/job/canrefresh").then((res) => {
      //   if (res.data.resultCode === 1) {
      if (this.infoType == "招聘信息") {
        // 兼职刷新
        let info = {
          type: this.workType,
          id: e,
        };
        this.$http.post("company/job/refreshjob", info).then((res) => {
          if (res.data.resultCode === 1) {
            this.$message.success("刷新成功！");
          }
        });
      } else {
        // 求职刷新
        let info = {
          id: e,
        };
        this.$http.post("company/resume/refreshresume", info).then((res) => {
          if (res.data.resultCode === 1) {
            this.$message.success("刷新成功！");
          }
        });
      }
      //   } else {
      //     this.$message.warning("您现在没有刷新次数了！");
      //   }
      // });
    },
    delInfo(e) {
      let info = {
        type: this.workType,
        ids: [e],
        status: 5,
      };
      this.$http.post("company/job/deljob", info).then((res) => {
        if (res.data.resultCode === 1) {
          this.$message.success("删除成功！");
          this.searchPost();
        }
      });
    },
    comTag(id, flag) {
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          if (res.data.resultCode === 1) {
            if (res.data.resultData.u_grade_text != "") {
              // res.data.resultData.u_grade_text.slice(-2);
              let info = {
                id: id,
                flag: flag,
                type: this.workType,
              };
              this.$http.post("company/job/flagjob", info).then((res) => {
                if (res.data.resultCode === 1) {
                  this.$message.success("标记成功！");
                  this.searchPost();
                }
              });
            } else {
              this.$message.warning(
                "您还不是会员，你可以前往会员服务升级会员！"
              );
            }
          }
        });
    },
    buyVip() {
      this.$router.push("vipPay");
    },
    delAll() {
      if (this.idsList.length == 0) {
        this.$message.warning("您未选择要删除的项！");
        return;
      }
      if (this.infoType == "招聘信息") {
        let info = {
          type: this.workType,
          ids: this.idsList,
          status: 5,
        };
        this.$http.post("company/job/deljob", info).then((res) => {
          if (res.data.resultCode === 1) {
            this.$message.success("批量删除成功！");
            this.searchPost();
          }
        });
      } else {
        let info = {
          ids: this.idsList,
          status: 5,
        };
        this.$http.post("company/resume/delresume", info).then((res) => {
          if (res.data.resultCode === 1) {
            this.$message.success("批量删除成功！");
          }
        });
      }
    },
    /**
     * @description: 修改发布职位信息
     * @param  {*} info: 修改前的列表信息
     * @return {*}
     */

    editWorkbtn(info) {
      this.editworkDialog = true;
      this.$nextTick(() => {
        // 兼职 jzpostInfo
        if (this.workType == 1) {
          this.$refs.editInfo.flag = "1";
          this.$refs.editInfo.jzShow = true;
          this.$refs.editInfo.qzShow = false;
          this.$refs.editInfo.jzdisable = true;
          this.$refs.editInfo.qzdisable = false;
          this.$refs.editInfo.jzpostInfo = info;
          this.$refs.editInfo.cerList.push(info.j_certificate);
          this.$refs.editInfo.cerList.push(info.j_major);
          if (info.j_leve != "") {
            this.$refs.editInfo.cerList.push(info.j_leve);
          }
          this.$refs.editInfo.cityzwf =
            info.j_area_province + "-" + info.j_area_city;
        } else {
          // 全职 postInfo
          this.$refs.editInfo.flag = "2";
          this.$refs.editInfo.jzdisable = false;
          this.$refs.editInfo.qzdisable = true;
          this.$refs.editInfo.jzShow = false;
          this.$refs.editInfo.qzShow = true;
          this.$refs.editInfo.postInfo = info;
          this.$refs.editInfo.cerList.push(info.j_certificate);
          this.$refs.editInfo.cerList.push(info.j_major);
          this.$refs.editInfo.jobList.push(info.j_job_type);
          this.$refs.editInfo.jobList.push(info.j_job_major);
          this.$refs.editInfo.cityzwf = info.j_province + "-" + info.j_city;
        }
      });
    },
    /**
     * @description: 修改求职信息
     * @param {*} info: 修改前的列表信息
     */
    editPostbtn(info) {
      this.editPostDialog = true;
      this.$nextTick(() => {
        this.$refs.editpost.postInfo = info;
        this.$refs.editpost.cerdisable = true;
        this.$refs.editpost.cerList.push(info.certificate);
        this.$refs.editpost.cerList.push(info.major);
        this.$refs.editInfo.cityzwf =
          info.expected_province + "-" + info.expected_city;
      });
    },
    /**
     * @description: 提交兼职全职职位信息修改
     * @param  {*}
     * @return {*}
     */
    edit1() {
      if (this.$refs.editInfo.flag == 1) {
        let info = this.$refs.editInfo.jzpostInfo;
        info.type = 1;
        this.$http.post("company/job/editjob", info).then((res) => {
          if (res.data.resultCode === 1) {
            this.editworkDialog = false;
            this.searchPost();
          }
        });
      } else {
        let info = this.$refs.editInfo.postInfo;
        info.type = 2;
        this.$http.post("company/job/editjob", info).then((res) => {
          if (res.data.resultCode === 1) {
            this.editworkDialog = false;
            this.searchPost();
          }
        });
      }
    },
    // 修改求职
    edit2() {},
    // 跳转兼职详情
    gopartTime(item) {
      let routeUrl = this.$router.resolve({
        name: "partTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 跳转全职详情
    gofullTime(item) {
      let routeUrl = this.$router.resolve({
        name: "fullTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.searchInfo.approval_status = "2";
    this.getJobList();
    this.getCerList();
    this.getTableData1(); // 获取显示中信息
  },
};
</script>
<style scoped>
.tableBox {
  padding: 20px 0;
}
.fontWeight {
  font-size: 17px;
  font-weight: bold;
  color: #000;
}
.btnBox1 button {
  margin-right: 6px;
  padding: 3px;
}
/deep/ .jobInfo,
/deep/ .applyJob {
  width: 810px;
}
/deep/ .formInfoTitle {
  display: none;
}
/deep/ .infoBox {
  padding: 0 50px;
}
</style>
