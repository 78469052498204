<template>
  <!-- 求职form -->
  <div>
    <section class="applyJob">
      <el-form label-width="107px" :model="postInfo">
        <p class="formInfoTitle">
          <span>兼职求职</span>
          <span></span>
        </p>
        <div class="infoBox">
          <el-form-item
            label="期望职位类型"
            :rules="[
              {
                required: true,
              },
            ]"
          >
            <el-cascader
              style="width: 100%"
              v-model="jobList"
              placeholder="选择或搜索期望职位类型"
              :options="selJobList"
              :props="{
                checkStrictly: true,
                value: 'category_name',
                label: 'category_name',
                children: 'child',
              }"
              filterable
              clearable
            >
            </el-cascader>
            <el-checkbox v-model="tjzw" class="margin-left-20"
              >选择系统推荐的职位</el-checkbox
            >
          </el-form-item>
          <el-form-item
            label="社保要求"
            prop="social"
            :rules="[
              {
                required: true,
                message: '请选择社保要求',
                trigger: 'change',
              },
            ]"
          >
            <el-select v-model="postInfo.social" style="width: 100%">
              <el-option label="不限" value="不限"></el-option>
              <el-option label="唯一社保" value="唯一社保"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="目前状态"
            prop="now_status"
            :rules="[
              {
                required: true,
                message: '请选择目前状态',
                trigger: 'change',
              },
            ]"
          >
            <el-select v-model="postInfo.now_status" style="width: 100%">
              <el-option label="合同快到期" value="合同快到期"></el-option>
              <el-option label="已考过未领取" value="已考过未领取"></el-option>
              <el-option label="已退休" value="已退休"></el-option>
              <el-option label="应聘中" value="应聘中"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="到岗时间"
            prop="arrival_time"
            :rules="[
              {
                required: true,
                message: '请选择到岗时间',
                trigger: 'change',
              },
            ]"
          >
            <el-select v-model="postInfo.arrival_time" style="width: 100%">
              <el-option label="随时" value="随时"></el-option>
              <el-option label="2周内" value="2周内"></el-option>
              <el-option label="2周-1个月" value="2周-1个月"></el-option>
              <el-option label="1-3个月" value="1-3个月"></el-option>
              <el-option label="3个月以上" value="3个月以上"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="期望地区"
            :rules="[
              {
                required: true,
                message: '请选择期望地区',
                trigger: 'change',
              },
            ]"
          >
            <el-cascader
              size="large"
              :options="comcity1"
              v-model="selectedOptions1"
              @change="setUserCity"
              style="width: 100%;"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item
            label="证书价格"
            prop="zs_price"
            :rules="[
              {
                required: true,
                message: '请输入证书价格',
                trigger: 'blur',
              },
            ]"
          >
            <el-input-number
              v-model="postInfo.zs_price"
              placeholder="单位（万元）"
              :min="0"
              :controls="false"
              @change="getPrice"
            ></el-input-number>
          </el-form-item>
        </div>
        <p class="formInfoTitle">
          <span>人才信息</span>
          <span></span>
        </p>
        <div class="infoBox">
          <el-form-item
            label="人才名称"
            prop="tal_name"
            :rules="[
              {
                required: true,
                message: '请输入人才名称',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="postInfo.tal_name"></el-input>
          </el-form-item>
          <el-form-item
            label="性别"
            prop="sex"
            :rules="[
              {
                required: true,
                message: '请选择性别',
                trigger: 'change',
              },
            ]"
          >
            <el-radio v-model="postInfo.sex" label="1">男</el-radio>
            <el-radio v-model="postInfo.sex" label="2">女</el-radio>
          </el-form-item>
          <el-form-item
            label="人才电话"
            prop="phone"
            :rules="[
              {
                required: true,
                message: '请输入人才电话',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="postInfo.phone"></el-input>
          </el-form-item>
          <el-form-item label="生日" prop="birth">
            <el-date-picker
              style="width: 100%"
              type="date"
              value-format="yyyy-MM-dd"
              v-model="postInfo.birth"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="持有证书"
            style="position:relative"
            prop="register"
            :rules="[
              {
                required: true,
                message: '请选择持有证书和注册情况',
                trigger: 'change',
              },
            ]"
          >
            <el-cascader
              style="width: 70%"
              v-model="cerList"
              placeholder="选择或搜索持有证书"
              :disabled="cerdisable"
              :options="selCerList"
              :props="{
                checkStrictly: true,
                value: 'certificate',
                label: 'certificate',
                children: 'child',
              }"
              filterable
              clearable
            >
            </el-cascader>
            <el-select
              v-model="postInfo.register"
              placeholder="注册情况"
              style="width: 30%"
            >
              <el-option label="初始注册" value="初始注册"></el-option>
              <el-option label="转注册" value="转注册"></el-option>
              <el-option label="初转不限" value="初转不限"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="职位类型"
            prop="zc_need"
            :rules="[
              {
                required: true,
                message: '请选择职位类型和职称等级',
                trigger: 'change',
              },
            ]"
          >
            <el-cascader
              style="width: 70%"
              v-model="postList"
              placeholder="选择或搜索职位类型"
              :options="selPostList"
              :props="{
                checkStrictly: true,
                value: 'title',
                label: 'title',
                children: 'child',
              }"
              filterable
              clearable
            >
            </el-cascader>
            <el-select
              v-model="postInfo.zc_need"
              placeholder="职称等级"
              style="width: 30%"
            >
              <el-option label="不限" value="不限"></el-option>
              <el-option label="初级" value="初级"></el-option>
              <el-option label="中级" value="中级"></el-option>
              <el-option label="高级" value="高级"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作经验" prop="work_experience">
            <el-select v-model="postInfo.work_experience" style="width: 100%">
              <el-option label="1年内" value="1年内"></el-option>
              <el-option label="1-3年" value="1-3年"></el-option>
              <el-option label="3-5年" value="3-5年"></el-option>
              <el-option label="5-8年" value="5-8年"></el-option>
              <el-option label="8-10年" value="8-10年"></el-option>
              <el-option label="10年以上" value="10年以上"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历" prop="education_need">
            <el-select v-model="postInfo.education_need" style="width: 100%">
              <el-option label="高中" value="高中"></el-option>
              <el-option label="中技" value="中技"></el-option>
              <el-option label="中专" value="中专"></el-option>
              <el-option label="大专" value="大专"></el-option>
              <el-option label="本科" value="本科"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="MBA" value="MBA"></el-option>
              <el-option label="博士" value="博士"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="requireLabel" label="所在地区" prop="region">
            <el-cascader
              size="large"
              :options="comcity"
              v-model="selectedOptions"
              :placeholder="cityzwf"
              @change="setUseInfoSocial"
              style="width: 100%;"
            >
            </el-cascader>
          </el-form-item>
        </div>
      </el-form>
    </section>
  </div>
</template>
<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  name: "applyJob",
  data() {
    return {
      postInfo: {},
      cerList: [],
      jobList: [],
      postList: [],
      selCerList: [],
      selJobList: [],
      selPostList: [],
      selectedOptions: [],
      selectedOptions1: [],
      comcity: provinceAndCityData,
      comcity1: provinceAndCityData,
      destextLength: 1000,
      tjzw: false,
      cerdisable: false,
      cityzwf: "",
    };
  },
  methods: {
    // 城市选择
    setUseInfoSocial() {
      this.postInfo.region_province = CodeToText[this.selectedOptions[0]];
      this.postInfo.region_city = CodeToText[this.selectedOptions[1]];
    },
    setUserCity() {
      this.postInfo.expected_province = CodeToText[this.selectedOptions1[0]];
      this.postInfo.expected_city = CodeToText[this.selectedOptions1[1]];
    },
    // 证书下拉
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
    },
    // 职位下拉
    getJobList() {
      this.$http.post("index/certificate/postion").then((res) => {
        this.selJobList = res.data.resultData;
      });
    },
    // 职称下拉
    getPostList() {
      this.$http.post("index/certificate/zctitle").then((res) => {
        this.selPostList = res.data.resultData;
      });
    },
    // 描述字数限制
    infoInput() {
      this.destextLength = 1000 - this.postInfo.j_job_describe.length;
    },
    // 证书价格限制
    getPrice() {
      if (this.postInfo.zs_price < 0 || this.postInfo.zs_price > 50) {
        this.$message.warning("证书最小价格不得低于0元，最大不得超过50万元");
        if (this.postInfo.zs_price > 50) {
          this.$set(this.postInfo, "zs_price", 50);
        }
      }
    },
  },
  mounted() {
    this.getCerList();
    this.getJobList();
    this.getPostList();
  },
};
</script>
<style scoped>
.formInfoTitle {
  padding: 40px;
}
.formInfoTitle span:first-child {
  font-size: 17px;
  font-weight: bold;
  color: #2573f1;
  border-bottom: 2px solid #2573f1;
}
.formInfoTitle span:last-child {
  color: #999;
  padding: 0px 10px;
}
.infoBox {
  padding: 0px 200px;
}
</style>
