import { render, staticRenderFns } from "./recruitInfo.vue?vue&type=template&id=ec13624c&scoped=true&"
import script from "./recruitInfo.vue?vue&type=script&lang=js&"
export * from "./recruitInfo.vue?vue&type=script&lang=js&"
import style0 from "./recruitInfo.vue?vue&type=style&index=0&id=ec13624c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec13624c",
  null
  
)

export default component.exports